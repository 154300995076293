/* LM */
#lm-page .left-half {
  width: 60%;
}

#lm-page .right-half {
  width: 40%;
}

.video-player {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 2vw;
}

.table-row {
  display: flex;
}

.table-left {
  width: 9.4vw;
}

.table-center {
  padding-left: 1vw;
  padding-right: 1vw;
}

#lm-page table {
  width: 100%;
  margin-top: 0.5vw;
}

table, td {
  border: 1px solid black;
  border-collapse: collapse;
}

