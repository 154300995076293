#tim-page .left-half {
  width: 90%;
}

#tim-page .right-half {
  width: 10%;
}

#tim-page .page-section-content {
  font-size: 0.8vw;
}