.modal-shell, .modal-back{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.9);

  width: 100vw;
  height: 100vh;
}

#contact-modal{
  position: fixed;
  top: 32vh;
  left: 40vw;
  z-index: 1001;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  width: 20vw;
  height: auto;

  background: white;
  padding: 1vw;
  box-sizing: border-box;
  border-radius: 5px;
}

#contact-modal .title {
  padding-bottom: 0.6vw;

  font-size: 1.4vw;
  text-decoration: underline;
}

#contact-modal .content {
  padding-bottom: 0.3vw;

  font-size: 1.2vw;
}

.carousel-modal, .image-modal {
  position: fixed;
  top: 10vh;
  left: 25vw;

  width: 50vw;
  height: 30vw;
}

.carousel-modal {
  position: fixed;
  top: 10vh;
  left: 20vw;

  width: 60vw;
  height: 36vw;
}

.image-modal {
  top: 20vh;
}

.modal-image:hover {
  cursor: pointer;
  opacity: 0.95;
}

