#warpage-page .page-image {
  height: auto;
  width: 90%;

  margin: 0.5vw 0;

  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 3px;
  box-sizing: border-box;
}

#warpage-page .split-section {
  justify-content: start;
}

#warpage-page .left-half {
  width: 60%;
}

#warpage-page .right-half {
  width: 30%;
  margin-left: 3%;
  height: auto;
}

#warpage-page .page-section {
  margin-bottom: 0.75vw;
}