/* BACKGROUND */
.background-image-container {
  position: fixed;
  top: 0;
  left: -10vw;
  z-index: -1;

  display: flex;
  flex-direction: column;

  width: 120vw;
  /* height: 100vh; */
  overflow: hidden;

  background-color: rgba(122, 194, 223, 0.45);
}

.background-image {
  width: 100%;
  height: calc(100vh - 4vw);
  opacity: 0.17;

  /* visibility: hidden; */
}

.background-spacer {
  width: 100%;
  height: 5vw;
}

.background-solid {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -10;

  width: 100vw;
  height: 100vh;

  background-color: white;
}
