/* NAV BAR */
.nav-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 6vw;
}

/* NAV LOGO */
.nav-logo {
  display: flex;

  width: 100%;
  height: 2.7vw;

  background-color: whitesmoke;

  padding: 0.5vw 0;
  /* box-shadow: 0px 1px 5px gray; */

  color: black;
}

.nav-logo .center {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.logo:hover {
  transition-duration: 0.3s;
  cursor: pointer;
  color: darkblue;
}

.abbreviated-logo {
  margin-bottom: 0.2vw;

  font-size: 1.8vw;
  line-height: 1.8vw;
  font-style: italic;
  font-weight: bold;
  text-decoration: underline;
}

.full-logo {
  font-size: 0.9vw;
  line-height: 0.9vw;
}

#contact-button {
  padding: 2px;

  font-size: 1vw;
  line-height: 1vw;
}

#contact-button:hover {
  transition-duration: 0.3s;

  cursor: pointer;
  color: darkblue;
}

/* NAV BUTTONS */
#nav-buttons {
  display: flex;

  width: 100%;
  height: 2.2vw;

  background-color: black;

  box-shadow: 0px 1.5px 1.5px gray;
}

#nav-buttons .center {
  display: flex;
  width: 80%;
}

#nav-buttons .left, #nav-buttons .right {
  width: 10%;
}

.nav-button {
  width: auto;
  height: 100%;

  padding-right: 2.4vw;
  box-sizing: border-box;

  font-size: 1vw;
  line-height: 2.2vw;
  vertical-align: middle;

  color: lightgray;
}

.nav-button:hover {
  transition-duration: 0.4s;
  cursor: pointer;
  color: white;
}

.nav-dropdown {
  position: fixed;

  margin-top: 1px;

  border-radius: 1px;
  box-shadow: 0px 0.2px 0.2px gray;

  background-color: white;
  color: rgb(98, 98, 98);
}

.nav-dropdown:hover {
  transition-duration: 0.4s;  
  cursor: pointer;
}

.drop-button {
  padding: 0 1vw;
  font-size: 0.9vw;

  display: flex;
  align-items: baseline;
}

.drop-button:hover{
  transition-duration: 0.2s;
  color: black;
}

.nav-dropdown a{
  color: rgb(98, 98, 98);
  padding: 0 1vw;
  font-size: 0.9vw;
}

a .dropbutton:hover {
  transition-duration: 0.2s;
  color: black;
}

.tds-download {
  position: fixed;
  right: 10%;

  height: auto;
  font-size: 1.1vw;
  line-height: 2.2vw;
  vertical-align: center;
  font-weight: bold;

  color: white;
}