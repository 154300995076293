#home-page {
  display: flex;
}

#home-page .left,
#home-page .right {
  width: 10%;
}

#home-page .center {
  width: 80%;
}

#home-top {
  margin: 2.5vw 0 0.8vw 0;
  box-sizing: border-box;

  font-size: 1.3vw;
  line-height: 2vw;
  font-style: italic;
  text-align: left;
  vertical-align: center;
  /* font-weight: bold; */

  color: black;
}

#home-bottom {
  display: flex;
  align-items: top;
  height: 50vh;

  margin-top: 5vh;
}

#home-image {
  width: 36vw;
  height: 24vw;

  margin-right: 5%; 

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  mix-blend-mode: multiply;

  padding: 10px;
  box-sizing: border-box;

  border: 1px solid black;
  border-radius: 5px;

  background: white;
}

#home-image-desc {
  margin-right: 3.1vw;

  font-size: 1vw;
  text-align: center;
  font-weight: bold;
}

#home-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 40%;
}

.home-button-group {
  padding-bottom: 10px;
}

.home-topic {
  font-size: 2vw;
  text-decoration: underline;
}

.home-button {
  font-size: 1.5vw;
  padding-left: 20px;

  border-radius: 5px;
}

.home-button:hover {
  transition-duration: 0.3s;

  cursor: pointer;
  color: darkblue;
  background: white;
}

#home-carousel-wrapper{
  width: 40vw;
  height: 23vw;

  /* border: black 1px solid; */
  background: none;
}

.home-slide {
  width: 100%;
  height: 24vw;

  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.5px 1.5px 1.5px gray;
  border-radius: 5px;

  padding-bottom: 1.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-slide-modal {
  width: auto;
  height: auto;

  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.5px 1.5px 1.5px gray;
  border-radius: 5px;

  padding-bottom: 1.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#assembly-gif {
  height: 20.25vw;
  width: 33.38vw;
}

#assembly-gif-big {
  height: 30vw;
  width: 50vw;
}

#mechanism-gif {
  height: 20.25vw;
  width: 37.5vw;
}

.loading-home {
  position: absolute; 
  width: 28vw;
  height: 30vw;
  z-index: 1000;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

#system-thermal {
  height: 20.25vw;
  width: 25.21vw;
}

.width-image {
  /* display: block; */
  width: 90%;
  height: auto;
  object-fit: scale-down;
}

/* HOME CAROUSEL */
/* TODO: ADD ARROWS BACK ELEGANTLY */
