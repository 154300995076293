body {
  margin: 0px;
}

button {
  padding: 0px;
}

img {
  width: 90%;
  height: auto;

  mix-blend-mode: multiply;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.italic {
  font-style: italic;
}

div, a{
  /* font-family: 'DINPro-Regular'; */
  font-family: 'Raleway';
  font-weight: lighter;

  font-weight: bold;
}

.center-content {
  display: flex;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.hidden {
  visibility: hidden;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.auto-height {
  height: auto;
}

/* PAGE LAYOUT */
.center {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;

  z-index: 10;
}

.left,
.right {
  width: 10%;
  height: 100%;

  background: none;
}

.split-section {
  display: flex;
  justify-content: space-between;
}

.split-section-col {
  display: flex;
  flex-direction: column;
}

.half,
.left-half,
.right-half {
  width: 48%;
  height: 100%;
}

.third {
  width: 33%;
  height: 100%;
}

.two-third {
  width: 66%;
  /* height: 100%; */
}

.fourth {
  width: 25%;
}


/* BORDER AND COLOR */
.border-back {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.white-back {
  background-color: white;
  padding: 0 2vw;
}

.font-gray {
  color: gray;
}

.blue-link {
  color: blue;
}

.blue-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.underline-title {
  padding-bottom: 0.5vw;
  text-decoration: underline;
  font-size: 1.2vw;
}