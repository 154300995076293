#lm-hs-page .page-banner, #lm-vc-page .page-banner, #lm-lid-page .page-banner {
  margin-bottom: 4.8vw;
}

#lm-lid-page .page-banner {
  margin-bottom: 3.6vw;
}

#lm-hs-page .left-half, #lm-vc-page .left-half, #lm-lid-page .left-half {
  display: flex;
  justify-content: center;
  width: 50%;

}

#lm-hs-page .right-half, #lm-vc-page .right-half, #lm-lid-page .right-half {
  display: flex;

  width: 50%;
  margin-left: 0%;
  height: auto;
}

#lm-hs-page .page-section-content, #lm-vc-page .page-section-content, #lm-lid-page .page-section-content {
  font-size: 1.2vw;
  line-height: 2vw;

  margin-top: 2vw;
}

#lm-hs-page .page-section, #lm-vc-page .page-section, #lm-lid-page .page-section {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.camera-photo {
  width: 27vw;
  height: 18vw;
}

#mechanism-product {
  width: 27vw;
  height: 14.5vw;
}

.product.demo {
  text-align: left;
}

.loading-product {
  position: absolute;
  width: 28vw;
  height: 25vw;
  z-index: 1000;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 1vw;
}