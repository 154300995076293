.slide-title, .demo-slide-title {
  width: 100%;

  font-size: 1vw;
  line-height: 2vw;;
  font-style: italic;
  text-align: center;
  font-weight: bold;

  color: black;
}

.demo-slide-title {
  width: 100%;

  font-size: 1.4vw;
  text-decoration: underline;
}

.slide-content {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow: hidden;
}

.demo-slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.demo-slide-content .width-image {
  height: 19vw;
}

.demo-slide-content .demo-slide-description {
  padding-left: 3.8vw;
  padding-right: 3.8vw;

  font-size: 1.2vw;
}

.product-carousel-wrapper {
  width: 40vw;
  height: 23vw;
}

.product-slide .demo-slide {
  width: 80%;
  height: 21vw;

  background-color: rgba(255, 255, 255, 1);
  border: 1px solid black;
  border-radius: 5px;

  padding-bottom: 1.5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}


.rec.rec-arrow.rec-arrow-right, .rec.rec-arrow.rec-arrow-left {
  display: none;
}

.rec.rec-slider-container {
  /* margin-top: 10px; */
}

.rec.rec-dot {
  background-color: gray;
  box-shadow: none;
  
  width: 12px;
  height: 12px;
}

.no-dots .rec.rec-dot {
  display: none;
}

.rec.rec-dot:hover {
  box-shadow: 0 0 1px 2px gray;
}

.rec.rec-dot.rec-dot_active {
  border: none;
  background-color: red;
  box-shadow: none;

  width: 12px;
  height: 12px;
}

.rec.rec-dot.rec-dot_active:hover {
  box-shadow: 0 0 1px 2px red;
}