/* PAGE GENERAL FORMAT */
.page {
  position: fixed;
  top: 5vw;
  left: 0px;

  display: flex;

  width: 100%;
  height: calc(100vh - 4vw);

  /* background-image: url("../src/assets/images/background.png") */
}

.page-banner {
  width: 100%;

  margin: 1.5vw 0 1vw 0;

  color: black;

  font-size: 2vw;
  line-height: 3vw;
  text-align: left;
  font-weight: bold;
}

.page-banner-text {
  font-weight: bold;
}

.page-buttons {
  display: flex;

  padding-left: 2vw;

  font-size: 1vw;
  line-height: 1.2vw;
  font-style: italic;
}

/* .page-button {
  color: blue;
}

.page-button:hover {
  cursor: pointer;
  text-decoration: underline;
} */

.page-button-spacer {
  padding: 0 0.5vw;
  font-style: normal;
}

.page-section {
  width: 100%;
  margin-bottom: 0.5vw;

  box-sizing: border-box;
}

.page-section-title {
  font-size: 1.2vw;
  text-decoration: underline;
  font-weight: bold;
}

.page-section-content {
  padding: 0.2vw 0 0.5vw 1vw;
  font-size: 0.9vw;
}