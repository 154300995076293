.patent-info {
  padding-bottom: 20px;
  font-size: 0.9vw;
}

.patent-title {
  font-size: 1.1vw;
  color: rgb(0, 0, 80);
  font-weight: bold;

  text-decoration: underline;
}