#team-page .center {
  display: flex;
  flex-direction: column;
}

.team-member {
  display: flex;

  height: 30%;
  width: 100%;

  background-color: white;

  padding: 1vw;
  margin-bottom: 1vw;
}

.team-member-left {
  width: 20%;
}

.team-member-left img {
  width: 100%;

  border-radius: 100%;
}

.team-member-email,
.team-member-phone {
  width: 100%;

  font-size: 0.8vw;
  font-family: Arial, Helvetica, sans-serif;
  color: grey;
  text-align: center;
}

.team-member-right {
  width: 80%;

  padding-left: 30px;
}

.team-member-right .team-member-line {
  height: 0.7vw;
  width: 10vw;

  border-top: 0.1vw solid gray;
  margin-top: 1vw;
}

.team-member-right .team-member-name {
  font-size: 1.1vw;
  font-family: Arial, Helvetica, sans-serif;
  color: grey;
  line-height: 1.1vw;
  vertical-align: middle;
}

.team-member-right .team-member-role {
  font-size: 1vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  line-height: 1vw;
  vertical-align: middle;
}

.team-member-right .team-member-bio {
  padding-top: 0.5vw;

  font-size: 0.9vw;
  font-family: Arial, Helvetica, sans-serif;
}